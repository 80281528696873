import React from 'react'
import { Header } from 'components/theme'
import { Container } from 'components/common'
import { useStaticQuery, graphql } from 'gatsby'
import Illustrations from 'components/illustrations'
import RoundImage from 'react-rounded-image'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

const Intro = () => {
  const { DevIllustration } = Illustrations
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            src
            height
            width
          }
        }
      }
    }
  `)
  const profileImage = data.file.childImageSharp.fixed

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details>
          <h1>Jonatan Lund</h1>
          <RoundImage
            image={profileImage.src}
            roundedSize="0"
            roundedColor="#00000000"
            imageWidth={profileImage.width}
            imageHeight={profileImage.height}
          />
        </Details>
        <Thumbnail>
          <DevIllustration />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  )
}

export default Intro
