import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 2rem 0;
`
export const CompanyContainer = styled.div`
  padding-bottom: 2rem;
  p {
    font-weight: normal;
    line-height: 1.3;
    color: #707070;
  }
`

export const CompanyHeader = styled.h2`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
`
export const CompanyLink = styled.a`
  text-decoration: none;
`
