import React from 'react'
import { Section, Container } from 'components/common'
import { TimeUtil } from '../../../util'
import { EducationHeader, EducationLink } from './styles'

const education = () => (
  <Section as={Container} id="education">
    <h1>Education</h1>
    <hr />
    <EducationHeader>
      <EducationLink href="https://www.ntnu.edu/">Norwegian University of Science and Technology</EducationLink>
      <span>{TimeUtil.getTimeframe(2012, 2017)}</span>
    </EducationHeader>
    <p>Master of Science in Computer Science</p>
  </Section>
)

export default education
