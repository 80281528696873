import styled from 'styled-components'
import Colors from 'components/tokens/colors'

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Card = styled.a`
  width: 31%;
  height: 100%;
  margin-bottom: 1rem;
  border-radius: 2px;
  background: #fff;
  text-decoration: none;

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 26px 0 rgba(0, 0, 0, 0.09);
    transition: 0.3s;
    color: ${Colors.primaryText};
  }

  @media (max-width: 1080px) {
    width: 48%;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const CardImage = styled.div`
  height: 40%;
  display: flex;
  justify-content: center;

  img {
    margin: 0;
    object-fit: cover;
  }
`

export const CardContent = styled.div`
  padding: 1rem;
`
