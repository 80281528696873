import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'components/common'
import dev from 'assets/illustrations/skills.svg'
import { useStaticQuery, graphql } from 'gatsby'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { section: { eq: "about" } }) {
        frontmatter {
          section
        }
        html
      }
    }
  `)
  const { html } = data.markdownRemark
  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Details dangerouslySetInnerHTML={{ __html: html }}></Details>
        <Thumbnail>
          <img src={dev} alt="I’m John and I’m a Backend & Devops engineer!" />
        </Thumbnail>
      </SkillsWrapper>
    </Wrapper>
  )
}

export default About
