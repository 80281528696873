import React from 'react'
import { Container } from 'components/common'
import { TimeUtil } from '../../../util'
import { Wrapper, CompanyContainer, CompanyHeader, CompanyLink } from './styles'
import { WorkData } from '../../../data'

const work = () => (
  <Wrapper as={Container} id="work">
    <h1>Work</h1>
    <hr />
    {WorkData.map(company => (
      <CompanyContainer key={company.companyName}>
        <CompanyHeader>
          <CompanyLink href={company.companyLink}>{company.companyName}</CompanyLink>
          <span>{TimeUtil.getTimeframe(company.from, company.to)}</span>
        </CompanyHeader>
        <p>
          {company.location}
          <br />
          {company.position}
        </p>
      </CompanyContainer>
    ))}
  </Wrapper>
)

export default work
