import React from 'react'
import { Container, Chip } from 'components/common'
import { Wrapper, Category } from './styles'
import { SkillsData } from '../../../data'

const Skills = () => {
  const ignoreCaseSort = (a, b) => {
    const itemA = a.toLowerCase()
    const itemB = b.toLowerCase()

    if (itemA < itemB) return -1
    if (itemA > itemB) return 1
    return 0
  }

  return (
    <Wrapper as={Container} id="skills">
      <h1>Skills</h1>
      <hr />
      {SkillsData.map(({ name, skills }) => {
        const sortedSkills = skills.sort(ignoreCaseSort)
        return (
          <Category key={name}>
            <h2>{name}</h2>
            {sortedSkills.map(skill => (
              <Chip key={skill}>{skill}</Chip>
            ))}
          </Category>
        )
      })}
    </Wrapper>
  )
}

export default Skills
