import React from 'react'
import { Container } from 'components/common'
import contact from 'assets/illustrations/contact.svg'
import { Wrapper, Details, Thumbnail } from './styles'
import ContactForm from './ContactForm'

const Contact = () => (
  <Wrapper as={Container} id="contact">
    <Details>
      <h2>Contact</h2>
      <p>Do you have any questions? Feel free to use the contact form below to reach out to me.</p>
      <ContactForm />
    </Details>
    <Thumbnail>
      <img src={contact} alt="I’m John and I’m a Backend & Devops engineer!" />
    </Thumbnail>
  </Wrapper>
)

export default Contact
