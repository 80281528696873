import styled from 'styled-components'

export const EducationHeader = styled.h2`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
`

export const EducationLink = styled.a`
  text-decoration: none;
`
