import React, { useState, useEffect } from 'react'
import GhostContentAPI from '@tryghost/content-api'
import { ContentWrapper } from 'components/common'
import { CardsContainer, Card, CardContent, CardImage } from './styles'

const ghostApi = new GhostContentAPI({
  url: process.env.GATSBY_BLOG_API_URL,
  key: process.env.GATSBY_BLOG_API_KEY,
  version: 'v3'
})

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([])

  useEffect(() => {
    const fetchBlogPosts = async () => {
      const posts = await ghostApi.posts.browse({ limit: 6 })
      setBlogPosts(posts)
    }

    fetchBlogPosts()
  }, [])

  return (
    <ContentWrapper id="blog">
      <h1>Blog</h1>
      <hr />
      <h2>Latest blog posts</h2>
      <CardsContainer>
        {blogPosts.map(blogPost => (
          <Card href={blogPost.url} key={blogPost.id} target="_blank">
            <CardImage>
              <img src={blogPost.feature_image} alt={blogPost.title} />
            </CardImage>
            <CardContent>
              <h3>{blogPost.title}</h3>
              <p>{blogPost.meta_description}</p>
            </CardContent>
          </Card>
        ))}
      </CardsContainer>
    </ContentWrapper>
  )
}

export default Blog
